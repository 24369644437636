<template>

  <b-modal ref="preview-modal" content-class="post-preview-container" v-model="show" :size="size" centered hide-header hide-footer @hide="close">

    <b-overlay :show="busy">

      <div class="preview-title text-center" v-if="state==='preview'">
        <h4 class="title">Here's what your post would look like in the wild!</h4>
        <b-button @click="close()" variant="primary">SHOW ME MORE!</b-button>        
      </div>

      <div class="post-preview">

        <div class="post" :class="state==='preview'? 'preview':''">
          <Instagram v-if="type==='instagram'" :post="post" :policy="policy" :preview="preview" />
          <Facebook v-if="type==='facebook'" :post="post" :policy="policy" :preview="preview" />
          <Linkedin v-if="type==='linkedin'" :post="post" :policy="policy" :preview="preview" />
          <Twitter v-if="type==='twitter'" :post="post" :policy="policy" :preview="preview" />
        </div>

      </div>

    </b-overlay>

  </b-modal>

</template>

<style>


.post-preview-container {
  background-color:  transparent!important;
  border: 0px;
  padding: 0px;
  margin: 0px;

  .preview-title {

    color: black;
    margin: 20px;
    font-size: 24px;
    font-weight: bold;

    button {
      background-color: #20C763;
      border:1px solid #20c763;
    }
  }

  .post-preview {

    border: 1px solid lightgrey;
    background-color: white!important;
    border-radius: 15px;
    margin: 20px;

    .post {
      padding: 0px;
    }

    .post.preview {
      margin: 20px;
      border: 0px solid lightgrey;
    }

    .actions {
      margin: 20px;
    }
    
  }

}
</style>


<script>

'use strict'

import { actions, getters } from '@/services/store'
import Onboarding from '@/services/onboarding'

import Instagram from './instagram'
import Facebook from './facebook'
import Linkedin from './linkedin'
import Twitter from './twitter'

import Vue from 'vue'

export default {

  name: 'PreviewDialog',

  data() {
    return {  
        type:'',
        size:'md',            
        busy:false,
        show:false,
        state: '',
        preview : undefined,
        dashboard: undefined
    }
  },

  props: {
    post: {
      type: Object,
      required: false
    },
    policy: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default:''
    }
  },

  created() {
    this.dashboard = getters.dashboard()
  },
  
  watch: {
    async post() {
        this.type = this.post? this.post.contentType==='temporary'? this.post.channel : this.post? this.channel : undefined : undefined
        this.size = this.type === 'twitter'? 'md' : 'lg'
        this.state = Onboarding.getState()
        this.show = this.post !== undefined
        if ( this.show ) {
   
          this.busy = true
          try {

            this.preview = await actions.fetchPreview(this.dashboard,this.post)

          } catch ( err ) {
            console.error(err)
          } finally {
            this.busy = false
          }
        }
    },
    show() {

      let modal = document.getElementsByClassName('modal');          
      if ( this.show && this.state === 'preview' ) {
        setTimeout(()=>{
          if ( modal && modal.length ) {
            modal[0].classList.add('onboarding')         
          }
        },50)
      } else {
        setTimeout(()=>{        
          if ( modal && modal.length ) {
            modal[0].classList.remove('onboarding')         
          }
        }, 50)
      }
      if ( !this.show && this.mode === 'onboard' ) {
        this.close()
      }
    }    
  },

  methods: {
      close(finish) {
      this.$emit('on-action',{
          action:'preview-closed',
          post: this.post,
          finish
      })
    }
  },

  computed: {
    annotated() {
        return this.$options.filters.links(this.post? this.post.content : '');        
    }
  },

  components: {
    Instagram,
    Facebook,
    Linkedin,
    Twitter
  }

}

</script>

