<template>
  <div class="results-header-v2 mb-4">

      <div class="d-flex justify-content-between">
        
        <!-- Select All Checkbox -->
        <div class="multi-select" @click="toggleSelectAll()">
          <i class="fa fa-2x" :class="multiSelectCls" />       
          <span class="label" v-if="$mq==='lg' || $mq==='xl'">Select All</span>
        </div>

        <div class="button-group">
          <!-- Trash selected button -->
          <b-button :disabled="!selections" variant="light" class="btn-trash" @click="onAction('delete-selections')" v-b-tooltip.hover :title="tooltip('delete')">
            <i class="fas fa-trash fa-lg"></i> 
            <span v-if="expanded"> TRASH </span>
          </b-button>

          <!-- Schedule button -->
          <b-button v-if="selections === 1" variant="success" class="btn-schedule" @click="onAction('schedule-selections')" v-b-tooltip.hover :title="tooltip('schedule')">
            <i class="fas fa-calendar-alt fa-lg"></i> 
            <span v-if="expanded"> SCHEDULE </span>
          </b-button>
  
        </div>
      </div>

  </div>
</template>

<script>

import Selections from '@/services/selections'
import { events } from '@/services/store'

export default {
  data() {
    return {
      channels: 0,
      selections: 0,      
      multiSelectCls:'none'
    };
  },
  props: {
    content: {
      type: Array,
      required:true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    errors: {
      type:Array,
      required:true
    }
  },
  created() {
    this.updateFromSelections()
    events.$on('selections-changed',this.updateFromSelections)
    events.$on('on-channels-selected', (ev)=>{
      this.channels = ev.accounts.length
    })
  },
  destroyed() {
    events.$off('selections-changed',this.updateFromSelections)
  },
  methods: {

    tooltip(action) {
      switch( action ) {
        case 'delete': {
          return `Trash ${this.selections} Post${this.selections>1?'s':''}`          
          break;
        }
        case 'schedule': {
          return `Schedule ${this.selections} Posts` //  to ${this.channels} Accounts`          
          break;
        }        
      }
    },
    updateFromSelections() {
      let selections = Selections.items()
      this.selections = selections.length
      let result = this.content.length && this.content.length === selections.length? 'fa-check all' : 
                  this.content.length && selections.length? 'fa-check some' : 'fa-check none'
      this.multiSelectCls = result
    },
    toggleSelectAll() {
      let selections = Selections.items()   
      if ( selections.length === this.content.length ) {
        Selections.clear()
      } else if ( selections.length ) {
        let unselected = this.content.filter((item)=>{
          return !selections.includes(item)
        })
        Selections.toggle(unselected)
      } else {
        Selections.toggle(this.content)
      }

    },
    onAction(action) {
      this.$emit('on-action',{
        action
      })
    }
  },
};
</script>

<style lang="scss">

.results-header-v2 {

  margin-top: 15px;

  .button-group {
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding-top: 0px;
    font-weight: 500;    
  }

  .multi-select {
    font-size: 12px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 500;
    margin: 5px 10px 0px 17px;
    i {
      padding: 2px;
      border: 1px solid lightgrey;
      border-radius: 6px;
      color: white;
    }
    i.some {
      color: #20c76e;
      opacity: 0.9;
      background-color: white;
      border: 1px solid lightgreen
    }
    i.all {
      color: white;
      background-color: #20c76e;
      border: 1px solid #20c76e;
    }
    .label {
      margin-left: 20px;
      font-size: 20px;
      padding-bottom: 5px;
    }
  }

  /* Button styles */
  .btn-trash {
    color: #707070;
    background-color: #E6E6E6;
    border: 0px;
    border-radius: 15px;
    padding: 10px 35px;    
  }

  .btn-schedule {
    background-color: #20c76e;
    border: 0px;
    color: white;
    border-radius: 15px;
    padding: 10px 35px;
    margin-left: 5px;
  }

  .fa-lg {
    font-size: 1.25em;
  }

}

</style>
