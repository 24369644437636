<template>

  <div class="container text-left publisher-view">

    <b-overlay :show="busy" opacity="0.8">

      <div class="row" v-if="remaining">

        <div class="col-md-12">

          <!-- not onboarded -->
          <div v-if="mode==='onboard' || !dashboard.channels.length || !user.onboarding.publisher || reconnect">

            <!-- onboarding - not previously connected -->
            <ConnectPublisher :mode="mode" v-if="partner !== 'none'" @complete-publisher="onCompletePublisher" @on-action="onAction" :remaining="remaining" :selections="selections" />

            <!-- post onboarding - previously connected to something or upgraded -->
            <SelectPublisher v-else @select-publisher="onSelectPublisher" />

          </div>

          <!-- connected, show channels list -->
          <ChannelsList v-else @on-connect-publisher="onConnectPublisher" @on-channel="computeRemaining" :remaining="remaining" :selections="selections" />

          <!-- channel select modal -->
          <b-modal static lazy class="channel-select-modal" size="lg" v-model="showSelect">

            <template #modal-header="">
              <div class="picker-header">
                <h3>Which social accounts do you want Lately to be able to publish to?</h3>
                <p>Your available {{showSelectType|channelname|titlecase}} social media accounts: </p>
              </div>
            </template>

            <template #default="">
              <div class="picker-body">
                  <div v-for="(channels,type) in needsSelection" :key="type">
                    <p class="picker-channel" v-for="channel in channels" :key="channel._id">
                      <b-form-checkbox :disabled="!selectionsRemaining.all && (!selections[channel._id] && !selectionsRemaining[type])" v-model="selections[channel._id]" @input="selected(channel)" name="check-button">
                        <span class="float-left picker-icon" v-if="!showSelectType">
                          <span class="fa-stack small">
                            <i class="fa fa-circle fa-stack-2x" :style="{'color': backgroundFor(channel.type)}"></i>
                            <i class="fab fa-stack-1x" :class="'fa-' + iconFor(channel.type)" :style="{color:'white'}"></i>
                          </span>
                        </span>
                        {{channel.content.name||channel.content.displayName}}
                      </b-form-checkbox>
                    </p>
                  </div>
              </div>
            </template>

            <template #modal-footer="">
              <div class="picker-footer">

                <!-- channels remaining based on product limits -->
                <ChannelsRemaining :remaining="remaining" :selections="selections" :type="showSelectType" v-if="user.onboarding.publisher"/>

                <p class="text-danger text-center" v-if="partner==='hootsuite'">*Hootsuite does not currently support Instagram Business accounts</p>

                <span class="float-left picker-icon" v-if="showSelectType">
                  <span class="fa-stack fa-lg">
                    <i class="fa fa-circle fa-stack-2x" :style="{'color': backgroundFor(showSelectType)}"></i>
                    <i class="fab fa-stack-1x" :class="'fa-' + iconFor(showSelectType)" :style="{color:'white'}"></i>
                  </span>
                </span>
                <span class="float-right">
                  <b-button class="cancel" @click="connect()" variant="outline">CANCEL</b-button>
                  <b-button class="connect" @click="connect(true)" :disabled="!selectionsValid" variant="primary">ADD SELECTED ACCOUNTS</b-button>
                </span>
              </div>
            </template>

          </b-modal>

        </div>
      </div>

    </b-overlay>

  </div>

</template>

<script>

import ChannelsRemaining from './ChannelsRemaining'
import ConnectPublisher from './ConnectPublisher'
import SelectPublisher from './SelectPublisher'
import ChannelsList from './ChannelsList'
import ChannelIcon from '../ChannelIcon'

import { actions, getters } from '@/services/store'
import { remainingLimits } from '@/services/product'
import { media } from '@/services/constants'
import Logger from '@/services/logger'
import User from '@/services/user'

export default {

  name: 'PublisherView',

  data() {
    return {
      user: false,
      busy: false,
      product: false,
      selections: {},
      partner: false,
      reconnect:false,
      dashboard: false,
      showSelect: false,
      needsSelection: {},
      showSelectType: '',
      selectionsValid: false,
      publisher: {},
      remaining: false,
      limits:{
        twitter:0,
        facebook:0,
        linkedin:0,
        instagram:0,
      }
    }
  },

  async created() {
    this.product = getters.product()
    this.init()
  },

  props: {
    mode: {
      type: String,
      default:''
    }
  },

  computed: {

    selectionsRemaining() {
      const remaining = Object.assign({},this.remaining)
      Object.keys(this.selections).forEach((id)=>{
        const ch = this.dashboard.channels.find((ch)=>{return ch._id  === id })
        if ( ch && this.selections[id] ) {
          if ( remaining.all ) {
            remaining.all -= 1
          } else {
            remaining[ch.type] -= 1
          }
        }
      })
      return remaining
    }

  },

  methods: {

    onAction(evt) {
      this.$emit('on-action',evt)
    },

    backgroundFor(publisher) {
      return media[publisher].color;
    },

    async computeRemaining() {
      this.remaining = await remainingLimits('channels','PublisherView.computeRemaining')
    },

    iconFor(property) {
      switch(property) {
        case 'facebook': return property+'-f';
        case 'linkedin': return 'linkedin-in';
        case 'twitter' : return 'square-x-twitter';
        default: return property;
      }
    },

    async portal() {
      this.showSelect = false
      this.busy = true
      const p = await actions.fetchStripePortalUrl()
      document.location.href = p.data.url
    },

    selected(channel) {
      this.selectionsValid = Object.keys(this.selections).length>0
      this.computeRemaining()
    },

    async onConnectPublisher() {

      try {
        const partner = getters.partner()
        if ( partner === 'lately' ) {
          this.reconnect = true
        } else {
          actions.connect(partner)
        }
      } catch( err ) {
        console.error(err)
      }
    },

    onSelectPublisher(publisher,connect) {

      if ( publisher === 'switch' ) {
        this.portal()
      } else {
        setTimeout(async ()=>{
          this.publisher.id = publisher
          this.publisher.onboarded = false
          this.user.signup.publisher = this.publisher
          await actions.patchUser(this.user._id,'signup.publisher',this.publisher)
          await this.computeRemaining()
          if ( publisher !== 'lately' && connect ) {
            return actions.connect(publisher.id)
          } else {
            this.$router.go()
          }
        })
      }

    },

    async connect(confirm) {

      try {

        this.busy=true
        this.showSelect = false

        // refreshes dashboard in store
        const action = confirm? 'select' : 'remove'
        await actions.updateChannels( this.dashboard, this.selections, action )
        this.selections = {}
        this.$toasted.success(`Channels updated`)
        setTimeout(()=>{
          this.$router.go()
        },100)

      } catch( err ) {
        console.error(err)
        this.$toasted.error(`Channels not updated`)
      }

    },

    async onCompletePublisher(publisher) {

      // v4 onboarding 
      if ( this.mode === 'onboard' ) {
        return this.$emit('on-action',{
          action: 'connect-complete'
        })
      } 

   
      try {
        if ( this.reconnect ) {
          this.reconnect = false
        } else if ( !User.getOnboardingKey('publisher') ) {

          // todo publisher onboarding should be consolidated into user.onboarding.publisher
          publisher.onboarded = true
          this.user.signup.publisher = publisher
          await actions.patchUser(this.user._id,'signup.publisher',publisher)
          await User.setOnboardingKey('publisher',true)

          // within onboarding 
          if ( this.$router.currentRoute.name === 'Onboarding' ) {
            this.$emit('onboarding-next',1)
          } 
          
          // publisher standalone
          else {
            this.$router.push( `/${this.dashboard._id}/onboarding?state=4.x` )
          }
        }
      } catch( err ) {
        console.error(err)
        this.$toasted.error(`Unable to update publisher - please try again in a few minutes`)
      }

    },

    async init() {

      this.user = getters.user()
      this.partner = getters.partner()
      this.publisher = this.user.signup && this.user.signup.publisher? this.user.signup.publisher : false

      // backfill publisher spec if necessary
      if ( !this.publisher ) {
        this.publisher = { id:this.partner, onboarded:false }
        this.user.signup = this.user.signup || {}
        this.user.signup.publisher = this.publisher
        await actions.patchUser(this.user._id,'signup.publisher',this.publisher)
      }

      // clear existing selections
      if ( this.dashboard && this.dashboard.channels ) {
        this.dashboard.channels.length = 0
      }

      this.selections = {}
      this.needsSelection = {}
      this.dashboard = getters.dashboard()

      try {

        // initialize needsSelection from dashboard
        this.dashboard.channels.reduce((acc,ch)=>{
          if ( ch.needsSelection ) {
            const bucket = acc[ch.type] = acc[ch.type] || []
            bucket.push(ch)
          }
          return acc;
        }, this.needsSelection )

        // initialize selections
        const keys = Object.keys(this.needsSelection)
        keys.forEach((type)=>{
          this.needsSelection[type].forEach((ch)=>{
            this.selections[ch._id] = false;
          })
        })

        if ( keys.length ) {

          if ( this.publisher.id === 'lately' ) {

            this.showSelectType = keys[0]
            this.showSelect = true

            // conditionally work thru generator
            if ( !this.showSelect && ( !this.user.onboarding || !this.user.onboarding.generator ) ) {
              this.$router.push(`/${this.dashboard._id}/onboarding`)
            }

          } else {
            this.showSelectType = ''
            this.showSelect = true
          }

        }

        await this.computeRemaining()

      } catch( err ) {
        console.error(err)
        this.$toasted.error(`We were unable to retrieve your account info at this time. Please wait a few minutes and try again`)
      }

    },

    onboardStep(event) {
      this.init()
    }

  },

  components: {
    ChannelsRemaining,
    ConnectPublisher,
    SelectPublisher,
    ChannelsList,
    ChannelIcon
  }

}
</script>

<style lang="scss" >

.publisher-view {

  margin-top: 40px;
  height: 100vh!important;

  .channel-select-modal {

    border-width: 0px;
    border-radius: 40px!important;

    .modal-content {

      padding: 36px;
      border-radius: 40px;
      padding: 36px 36px 48px 36px;

      .modal-header {
        padding: 0px;
        h3 {
          color: #2D2D2D;
          font-size: 42px;
          font-weight: bold;
          margin-bottom: 24px;
        }
        p {
          font-size: 18px;
          font-weight: bold;
          color: #2D2D2D;
          border: 0px 0px 1px 0px;
        }
        border: 0px;
        margin: 0px;
      }

      .modal-body {
        padding: 0px;
        .picker-channel {
          border-width: 1px 0px 0px 0px!important;
          border-style: solid;
          border-color: #E6E6E6;
          padding: 12px 0px 12px 0px!important;
          margin: 0px;
          p {
            font-size: 15px;
            font-weight: regular;
          }
        }
        .fa-stack.small { font-size: 0.6em; }
          i {
            vertical-align: middle;
            padding-right: 5px;
          }
        }

      .modal-footer {
        border-width: 1px 0px 0px 0px;
        border-style: solid;
        border-color: #E6E6E6;
        padding: 0px;
        margin: 0px;
      }

      .picker-footer {
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        margin: 24px 0px 0px 0px;
        button.cancel {
          padding: 10px 20px 10px 20px;
          background-color: #FFFFFF!important;
          border: 1px solid #E6E6E6;
          margin-right: 16px;
          color: #9D9D9D;
        }
        button.cancel:hover {
          border-color: grey;
          color: grey;
        }
        button.connect {
          padding: 10px 20px 10px 20px;
          border: 1px solid #008CFF;
          background-color:#008CFF;
          color: #FFFFFF;
        }
        button.connect:hover {
          background-color: grey;
        }
        p {
          font-size: 11px;
        }
      }
    }

  }

}

</style>
