<template>

  <!--
    REWRITE OF THE GENERATOR WORKFLOW PER https://app.asana.com/0/1206858696947582/1208078754325064
    IMPLEMENTED AS A PARALLEL COMPONENT TO FACILITATE A/B TESTING OR FALLBACK IF NECESSARY
  -->

  <!-- busy overlay -->
  <b-overlay :show="busy">

    <!-- tone picker -->
    <TonePicker @on-tone-selected="onToneSelected" :dflt="config.tone" :show="showTonePicker" v-if="config"/>

    <!-- enclosing container -->
    <div class="container-fluid generator-launcher-v2" v-if="config">

      <div class="row alerts" v-if="mode!=='onboard'&&needsConnect">
        <div class="col-md-10 offset-1 nopad">
          <div class="alert">
            <div class="badge">
              <i class="fa-duotone fa-solid fa-circle-exclamation fa-4x"/>
            </div>
            <div class="cta">
              <p class="lead">Action Needed</p>
              <p class="body">Connect at least one social channel to start generating content</p>
              <router-link :to="{ name:'Publisher', dashboardId:dashboard._id }" href="#">              
                Connect Now
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- top level options -->
      <div class="row options" :class="config.type==='media'? 'media':''">

        <div class="col-md-10 offset-1">

          <!-- media config cancel-->
          <a class="cancel" @click="cancel()" v-if="config.type==='media'"><i class="fa fa-chevron-left" /> CANCEL</a>

          <div class="row" v-if="config.type!=='media'">

            <div class="col-md-6 source-type" @click="setState(option)" v-for="option in options" :key="option.subtype">
              <div class="option" :class="classFor(option)">
                <h4>{{option.title}}</h4>
                <p>{{option.desc}}</p>
              </div>
            </div>

          </div>

        </div>
      </div>

      <!-- config for selected type -->
      <div class="row">
        <div class="config col-md-10 offset-1">

            <div class="option-config" :class="configClsFor(['url'])">
              <div class="option-url">
                <input v-model="config.text.url" ref="url-entry" type="text" class="form-control" placeholder="Paste a URL" />
              </div>      
            </div>

            <div class="option-config option-prompt" :class="configClsFor(['prompt'])">

              <div class="row config" v-if="!['url','media','upload'].includes(config.subtype)">
                <div class="col-md-10 offset-1 action-link">
                  <p class="secondary">Want to append a link in each generated result? (Optional)</p>
                  <input class="link form-control" v-model="config.links.all" placeholder="Paste a URL" />
                  <p v-if="linkerrors" class="text-danger"><small>{{ linkerrors }}</small></p>
                </div>
              </div>

                <textarea v-model="config.text.prompt" ref="prompt-entry" rows="6" class="form-control" placeholder="'Write me a thoughtful article on health and wellness'"></textarea>
                <div class="prompt-actions">
                  <p class="text-right">
                    <span v-if="config.text.composed" :class="config.text.composed? promptSummary.chars < 1000? 'text-danger':'text-success':'text-info'">
                    {{promptSummary.words}} words, {{promptSummary.chars}} characters</span>
                    <b-button @click="compose" :disabled="busy || config.text.prompt.length < 5" variant="secondary">COMPOSE</b-button>
                  </p>
                </div>
            </div>

            <div class="option-config option-paste" :class="configClsFor(['paste'])">

              <div class="row config" v-if="!['url','media','upload'].includes(config.subtype)">
                <div class="col-md-10 offset-1 action-link">
                  <p class="secondary">Want to append a link in each generated result? (Optional)</p>
                  <input class="link form-control" v-model="config.links.all" placeholder="Paste a URL" />
                  <p v-if="linkerrors" class="text-danger"><small>{{ linkerrors }}</small></p>
                </div>
              </div>

              <textarea v-model="config.text.pasted" ref="paste-entry" rows="6" class="form-control" placeholder="Paste your content here"></textarea>
              <div class="paste-actions">
                <p class="text-right">
                  <span :class="pasteSummary.chars < 1000? 'text-danger':'text-success'" >
                  {{pasteSummary.words}} words, {{pasteSummary.chars}} characters</span>
                </p>
              </div>
            </div>

            <div class="option-config option-upload" :class="configClsFor(['upload','media','audio','video'])">

              <p class="secondary thumbnail">Thumbnail</p>

              <div class="row thumbnails">
                <div class="col-md-4" >
                  <div class="preview text-center" v-if="!config.media.source" @click="pickFile($event,'source')">
                    <p><i class="fa fa-upload"></i></p>
                    <p>Upload Source</p>
                  </div>
                  <div v-else>
                    <MediaView :source="config.media.source" :policy="policy" :actions="[{source:'source',label:'Remove',action:'remove'}]" @on-media-action="onMediaAction" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="preview text-center" v-if="!config.media.intro" @click="pickFile($event,'intro')">
                    <p><i class="fa fa-upload"></i></p>
                    <p>Upload Intro</p>
                  </div>
                  <div v-else>
                    <MediaView :source="config.media.intro" :policy="policy" :actions="[{source:'intro',label:'Remove',action:'remove'}]" @on-media-action="onMediaAction" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="preview text-center" v-if="!config.media.outro" @click="pickFile($event,'outro')">
                    <p><i class="fa fa-upload"></i></p>
                    <p>Upload Outro</p>
                  </div>
                  <div v-else>
                    <MediaView :source="config.media.outro" :policy="policy" :actions="[{source:'outro',label:'Remove',action:'remove'}]" @on-media-action="onMediaAction" />
                  </div>
                </div>
              </div>

              <div class="row media-settings">

                <div class="col-md-10 offset-1">

                  <div class="d-flex justify-content-left video-caption-switch">
                    <p>Include Captions:</p>
                    <b-form-checkbox size="md" v-model="config.media.caption" name="check-button" switch></b-form-checkbox>
                  </div>

                  <LanguagePicker class="language-picker" :model="config.media" align="left" @on-language-selected="onLanguageSelected" />

                  <div class="link">
                    <p class="secondary link">Want to append a link in each generated result? (Optional)</p>
                    <input class="link form-control" v-model="config.media.link" placeholder="Paste a URL" />
                  </div>

                </div>

              </div>

            </div>

        </div>
      </div>

      <div class="row" v-if="errors">
        <div class="col-md-10 offset-1 errors">
          <div class="text-left">
            <p class="text-danger"><small>{{ errors }}</small></p>
          </div>
        </div>
      </div>

      <div class="row onboard-link" v-if="mode==='onboard'">      
        <div class="col-md-10 offset-1">
          <p v-if="mode==='onboard'">
            <a href="#" @click="setOnboardUrl()">Try this link if you don't have one</a>
          </p>              
        </div>
      </div>

      <div class="row" v-if="validConfig">
        <div class="col-md-10 offset-1 actions">
            <div class="text-center">
              <b-button class="action" @click="next()">GENERATE</b-button>
            </div>
        </div>
      </div>

    </div>

  </b-overlay>

</template>


<script>

import Vue from 'vue'
import * as linkify from 'linkifyjs'

import { picker } from '@/services/files'
import { includes } from '@/services/product'
import Onboarding from '@/services/onboarding'
import { getters, actions, store } from '@/services/store';
import GeneratorSessionHelper from '@/services/generator-session'

import LanguagePicker from '@/components/LanguagePicker'
import MediaView from '@/components/MediaView'
import TonePicker from './TonePicker'

export default {

  name: 'Success',

  components: {
    LanguagePicker,
    TonePicker,
    MediaView
  },

  props: {
    sessionId: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      errors:'',
      linkerrors:'',
      user:null,
      busy: false,
      config: null,
      policy: null,
      session: null,
      dashboard:null,
      ready: false,
      helpText:'',
      onboarded: null,
      generating: false,
      showTonePicker:null,
      needsConnect:false,

      options: [{
            type:'text',
            subtype:'url',
            title:'Paste a URL',
            desc: 'Use a link from a blog, article, etc.',
            requiresOnboarding:false,
            requiresChannels:true,            
            requiresPerms: ''
        },{
            type:'media',
            subtype:'upload',
            title:'Upload Audio or Video',
            desc: 'Select a file to upload',
            requiresOnboarding:true,
            requiresChannels:true,                   
            requiresPerms: 'video-generate'
        },{
            subtype:'prompt',
            title:'Start from a Prompt',
            desc: 'Type a prompt to create longform text',
            requiresOnboarding:true,
            requiresChannels:true,                   
            requiresPerms: ''
        },{
            subtype:'paste',
            title:'Paste Longform Content',
            desc: 'Copy and paste your content directly',
            requiresOnboarding:true,
            requiresChannels:true,                   
            requiresPerms: ''
        }
      ]
    }
  },

  async created() {

    this.user = getters.user()
    this.dashboard = getters.dashboard()
    this.policy = await actions.fetchPolicy()
    this.onboarded = this.user.onboarding.generator
    let sessionId = this.$route.params.sessionId || this.sessionId

    this.needsConnect = this.dashboard.channels.length === 0

    // setup help text
    let helpText = ''
    if ( this.mode === 'onboard' ) {
      helpText = `<p>The first step is to Generate. Let's start with URL as an example source. <br/><br/><b>Paste in any link to a blog or 
        article and click <span class="text-success">GENERATE</span></b> to start generating posts from the content.<br/><br/>Pro Tip: A link to content with 1000 characters 
        will give the best results!</p>`
    } else {
      let connected = this.dashboard.channels.filter((ch)=>{
        return ch.auth && ch.content && !ch.needsSelection
      })
      if (!connected.length) {
        helpText =  `<p><b>Connect your social channels to start generating content!</b></p>`
      }
    }
    Onboarding.setHelpText(helpText)

    if ( sessionId ) {
      this.session = await actions.findSession(sessionId);
      if ( !this.session ) {
        this.$toasted.error(`Whoops, we could not find that session`)
      } else {
        this.config = GeneratorSessionHelper.decodeSession(this.session)
      }
    } else {
      this.config = GeneratorSessionHelper.createDetachedSession('text','url')
    }

    if ( !includes('video-generate') ) {
      this.generate('text')
    } else {
      this.ready = true
    }

  },

  computed: {

    promptSummary() {
      return this.textSummary(this.config.text.prompt)
    },
    pasteSummary() {
      return this.textSummary(this.config.text.pasted)
    },
    validConfig() {

      let result = false
      this.errors = ''
      this.linkerrors = ''

      // post onboarding enforce channel connect
      let dashboard = getters.dashboard()
      if ( this.mode !== 'onboard' && (!dashboard || !dashboard.channels.length) ) return false

      switch( this.config.subtype ) {
        case 'url' : {
          let text = this.config.text.url.trim()
          const urls = linkify.find( text.trim() ).filter((e)=>{return e.type==='url'})
          result = urls.length === 1
          if ( result && (urls[0].href.includes('://youtube')||urls[0].href.includes('://youtu.be'))) {
            this.errors = `Youtube links are not supported. If you'd like to use a video please upload the file. `
            result = false
          } else if ( !result && text.length > 3 ) {
            this.errors = `Please enter a valid URL`
            result = false
          }
          break;
        }
        case 'prompt': {
          result = !this.busy && this.config.text.composed && this.config.text.prompt.length >= 1000
          if (this.config.text.composed && !this.busy && !result ) {
            this.errors = `We'll need at least 1000 characters to generate from.`
          }
          if ( this.config.links.all.length ) {
            let text = this.config.links.all.trim()
            const urls = linkify.find( text.trim() ).filter((e)=>{return e.type==='url'})
            if ( urls.length !== 1 ) {
              this.linkerrors = 'Please provide a valid link'
              result = false
            }
          }
          break;
        }
        case 'paste': {
          result = this.config.text.pasted.length >= 1000
          if (this.config.text.pasted.length > 10 && !result ) {
            this.errors = `We'll need at least 1000 characters to generate from.`
          }
          if ( this.config.links.all.length ) {
            let text = this.config.links.all.trim()
            const urls = linkify.find( text.trim() ).filter((e)=>{return e.type==='url'})
            if ( urls.length !== 1 ) {
              this.linkerrors = 'Please provide a valid link'
              result = false
            }
          }
          break;
        }
        case 'video':
        case 'audio':
        case 'upload': {
          let validSource = this.config.media.source && this.config.media.source.url
          let validLink = !this.config.media.link || linkify.find( this.config.media.link.trim() ).filter((e)=>{return e.type==='url'}).length === 1
          result = validSource && validLink
          break;
        }
      }

      return result
    },
  },

  methods: {

    setOnboardUrl() {
      this.config.text.url = 'https://www.lately.ai/blog/lately-ais-ultimate-social-media-writing-guide-7-ways-to-inspire-action'
    },

    classFor(option) {

      let result = this.config.subtype===option.subtype? 'selected' : this.session? 'disabled' : ''
      if ( this.mode === 'onboard' ) {
        return option.requiresOnboarding? 'disabled': result
      } else {
        return this.needsConnect? 'disabled' : result
      }

    },

    configClsFor(subtype) {
      let cls = ''
      if ( subtype.includes(this.config.subtype) ) {
        cls += ' selected'
      }
      if ( this.errors ) {
        cls += ' error'
      }
      return cls
    },

    next() {
      if ( this.mode === 'onboard' ) {
        this.onToneSelected('natural') 
      } else {
        this.showTonePicker = true
      }
    },

    cancel() {
      if ( this.session ) {
        history.go(-1)
      } else {
        this.setState('text','url')
      }
    },

    // emitted by tone selector modal
    // proceed to generate
    async onToneSelected(tone) {

      // hide the tone picker
      this.showTonePicker = false

      // create or update a generator session if we have one
      if ( tone ) {

        this.busy = true
        try {

          let session = this.session
          if ( !session && this.sessionId ) {
            session = await actions.findSession(this.sessionId)
          }

          session = await GeneratorSessionHelper.createOrUpdateSession( session, this.config )

          await actions.generate( session, tone )
          this.$toasted.success(`Submitted!`)
          if ( this.mode === 'onboard') {
            this.$emit('on-action', {
              action:'generate',
              session
             } )
          } else {
            this.$router.push('/'+session.dashboard+'/results/'+session._id)
          }
        } catch ( err ) {
          console.error(err)
          this.$toasted.error(`Whoops that did not work - try again in a moment`)
        } finally {
          this.busy = false
        }

      }
    },

    textSummary(src='') {
      let chars = src.length
      let words = !src.length? 0 : src.trim().split(' ').length
      return { words, chars }
    },

    async compose(ev) {
      ev.stopPropagation()
      ev.preventDefault()

      this.busy = true
      this.config.text.composed = false

      try {
        const dashboard = getters.dashboard()
        const response = await actions.gptRequest( dashboard._id, 'text', this.config.text.prompt )
        Vue.nextTick(()=>{
          this.config.text.prompt = response.content.trim()
          this.config.text.composed = true
        })
      } catch ( err ) {
        console.error(err)
        this.$toasted.error(`We're not able to process your prompt at the moment, please try again in a few minutes.`)
      } finally {
        this.busy = false
      }
    },

    // currently supports remove only
    onMediaAction(ev) {
      this.config.media[ev.source] = undefined
    },

    async pickFile(ev,aspect) {

      ev.stopPropagation()
      ev.preventDefault()

      let accept = aspect === 'source'? ['audio/*','video/*'] : ['image/*','audio/*','video/*']

      // default config goes to cdnlately-v3 bucket
      const options = {
        fromSources: ['local_file_system'],
        maxSize: 500 * 1024 * 1024,
        accept: accept,
        minFiles: 1,
        maxFiles: 1,
      }

      const results = await picker.pickFromCloud( {}, options, this.policy )

      this.config.media[aspect] = results.filesUploaded.length? results.filesUploaded[0]:false
      this.config.media.valid = (this.config.media.source!=null)

    },

    onLanguageSelected(lang) {
      this.config.media.language = lang
    },

    setState(option) {

      // permit no selections when we require a connection
      if ( this.mode !== 'onboard' && this.needsConnect ) {
        return;
      }

      if ( this.mode !== 'onboard' || !option.requiresOnboarding ) {
        let type = option.type
        let subtype = option.subtype
        this.config.type = type
        this.config.subtype = subtype

        let ctrl = this.$refs[`${type}-entry`]
        if ( ctrl ) {
          this.$nextTick(()=>{
            ctrl.focus()
          },100)
        }
      }

    },

    async generate(source) {

      if ( (source === 'video' || source==='audio') && !includes('video-generate') ) {
        this.portal()
      } else {
        const resp = await actions.createSession(source)
        // this.$router.push(`/${store.dashboard._id}/generate/${resp.session._id}/${resp.count}`)
      }
    }

  }
}
</script>


<style lang="scss" >

.generator-launcher-v2 {

  font-family: Noto Sans;
  max-width: 70%;
  margin-top: 50px;
  height:100vh;

  .alerts {
    margin-bottom: 20px;
    .alert {
      font-size: 14px;
      display:flex;
      flex-direction:row;
      margin: 5px;
      background-color:#e8f8f2;
      border: 1px solid #20c763;
      border-radius: 10px;
      .badge {
        padding-top: 10px;
      }
      .cta {
        padding-left: 10px;
        p {
          margin-bottom: 0px;
          font-size: 14px;
        }
        p.lead {
          font-weight:bold;
        }
        a {

        }
      }
    }
  }

  .onboard-link {
    margin-top: 40px;
  }

  .heading {
    p {
      font-size: 42px;
      font-weight: bold;
      margin-bottom: 0px;
    }
  }

  textarea {
    resize: vertical;
    padding: 15px;
    background-color:#F2F2F2;
    border-radius: 10px 10px 0px 0px;
  }

  input {
    background-color:#F2F2F2;
  }

  .options {

    // margin-top: 65px;

    a.cancel {
      font-family: Noto Sans;
      font-weight: bold;
      color: grey;
    }

    .source-type {
      padding: 5px;
    }

    .option {

      background-color: white;
      border: 1px solid lightgrey;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 15px;

      h4 {
        font-size: 22px;
        font-weight: bold;
      }
      p {
        font-size: 14px;
        font-weight: regular;
        padding: 0px 20px 0px 0px;
        margin-bottom:0px;
      }
    }

    .option.selected {
      background-color: #E8F8F2;
      border: 2px solid #20C763;
    }

    .option.disabled {
      opacity: 1;
      color: lightgrey;
    }

  }

  .options.media {
    margin-top:25px;
  }

  .config {

    border: 0px;
    padding: 0px;

    input, textarea {
      border: none;
      border-radius: 10px;
    }

    input:focus, textarea:focus {
      background-color:#f2f2f2;
      outline: none !important;
      box-shadow: none;
    }

    .option-config {
      display: none;
      margin: 5px;
      border-radius: 10px;
      border: 1px solid;
    }

    .option-config.selected {
      display: block;
      border: 1px solid lightgrey;
    }

    .option-config.selected.error {
      border: 1px solid #dc3545;
    }

    .option-prompt {

      padding: 0px;
      border-radius: 10px;
      border: 1px 0px 0px 0px solid lightgrey;
      background-color: white;

      textarea {
        border: none;
        border-radius: 0px 0px 0px 0px;
      }

      .prompt-actions {

        border-radius: 0px 0px 10px 10px;
        background-color: white;
        height: 100%;

        p {
          font-size: 14px;
          text-align: right;
          border: 0px;
          margin: 0px;
        }

        button {
          margin: 10px;
          font-size: 14px;
          font-weight: bold;
          padding: 5px 30px 5px 30px;
          border-radius: 20px;
        }

      }

    }

    .option-paste {

      padding: 0px;
      border-radius: 10px;
      border: 1px 0px 0px 0px solid lightgrey;
      background-color: white;

      textarea {
        border: none;
        border-radius: 0px 0px 0px 0px;
      }

      .paste-actions {

        border-radius: 0px 0px 10px 10px;
        background-color: white;
        height: 100%;

        p {
          font-size: 14px;
          text-align: right;
          border: 0px;
          margin: 0px;
          padding: 5px;
        }

      }

    }

    .option-upload {
      padding: 20px;
      margin-top: 20px;
      border: 1px 0px 0px 0px solid lightgrey;
      background-color: white;

      .preview {
        height: 150px;
        padding: 35px;
        color: #20C763;
        border: 2px solid #20C763;
        border-radius: 10px;
        margin-top:10px;
      }

      p {
        font-size: 14px;
        margin-bottom: 20px;
      }

      p.link {
        margin-bottom: 5px;
      }

      .video-caption-switch {
        margin-top: 10px;
        p {
          font-size: 14px;
          margin-right: 10px;
        }
      }

      p.thumbnail {
        margin: 10px 0px 0px 0px;
      }

      input.link {
        margin: 0px;
        border: 1px solid lightgrey;
        border-radius: 5px;
        p {
          margin: 0px;
        }
      }
    }
  }

  .action-link {

    max-width: 98%!important;
    margin: 10px;
    padding: 15px;

    p {
      font-size: 14px;
      margin-bottom: 5px;
    }

    input {
      border-radius: 6px;
    }

  }

  .actions {

    margin-top: 20px;

    button {
      padding: 10px 40px 10px 40px;
      background-color: #20C763;
      border: 0px;
    }

  }

}
</style>


